import React from "react";
import PropTypes from "prop-types";

//import Constants
import { surveyQuestionsConstants } from "../../config/constants";

//import Components
import Question from "./Question";

function Questions(props) {
  // Get Props Values
  const { classes, langCode, catIndex, catOrder, questionCategory, questions, validatedQues, questionPanelHeight, continueBtn, changeValue, continueBtn2, changeValue2} = props;

  const categoryQuestions = questions.filter((question) => question.category === questionCategory);

  let quesCount = 0;
  if (catIndex > 0) {
    for (let i = 0; i < catIndex; i++) {
      const qCat = catOrder[i];
      const catQues = questions.filter((question) => question.category === qCat);
      quesCount += catQues.length;
    }
  }

  return (
    <>
      {categoryQuestions.map((item, index) => {
        const prevQues = index > 0 ? categoryQuestions[index - 1] : null;
        const singleSelectRepeat =
          item.qusType == surveyQuestionsConstants.SINGLE_SELECT && prevQues && item.qusType == prevQues.qusType && JSON.stringify(item.ansSet[langCode]) == JSON.stringify(prevQues.ansSet[langCode])
            ? true
            : false;
        const requiredStatus = validatedQues.length > 0 && validatedQues.find((qid) => qid == item.baseQusId);
        const questionIndex = parseInt(quesCount) + index + 1;
        return (
          <div key={`div-${index}`} index={index}>
            <Question
              classes={classes}
              langCode={langCode}
              catIndex={catIndex}
              item={item}
              index={index}
              questionIndex={questionIndex}
              singleSelectRepeat={singleSelectRepeat}
              requiredStatus={requiredStatus ? true : false}
              validatedQues={validatedQues}
              questionPanelHeight={questionPanelHeight}
              continueBtn={continueBtn}
              changeValue={changeValue}
              continueBtn2={continueBtn2}
              changeValue2={changeValue2}
            />
          </div>
        );
      })}
    </>
  );
}

// default props
Questions.defaultProps = {
  classes: {},
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE,
  questionCategory: "",
  questions: [],
  validatedQues: [],
};

// prop types
Questions.propTypes = {
  classes: PropTypes.object,
  langCode: PropTypes.string,
  questionCategory: PropTypes.any,
  questions: PropTypes.array,
  validatedQues: PropTypes.array,
};

export default Questions;
