/* eslint-disable react/prop-types */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SvgIcon from '@mui/material/SvgIcon';
import moment from "moment";



// Import Actions
import { surveyAction, updateStateReducerAction } from "../../../../redux/actions";

// Import Icons
import { SettingAccord } from "../../../../assets/images/svgComponents/ImageComponent";


// Import Components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import DatePickerComponent from "../../../../components/DatePickerComponent/CustomDatePicker";
import AutoCompleteComponent from "../../../../components/AutoCompleteComponent/AutoCompleteComponent";
import SelectFieldComponent from "../../../../components/SelectFieldComponent/SelectFieldComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";

// constants
import { surveySettingsConstants, errorsConstants, surveyConstants } from "../../../../config/constants";
import { surveyConstant } from "../../../../redux/constants";
import { surveyTypes } from "../../../../config/constants";


// Import Actions
// import { updateStateReducerAction } from "../../../../redux/actions"

// Import Helpers
import { getReducerUpdatedData } from "../../../../helpers";

function SetupPanel(props) {
  // Get Props
  const { classes, data, expanded, questions, panel, panelError } = props;

  // Define State
  const [openPulseMatrixDialog, setOpenPulseMatrixDialog] = useState({
    open: false,
    data: {}
  });
  const [openPulseDateDialog, setOpenPulseDateDialog] = useState(false);
  const [openClosedStatusDialog, setOpenClosedStatusDialog] = useState(false);
  const [pulseCheck, setPulseCheck] = useState(false);
  const [frequency, setFrequency] = useState("");
  const { managerEffectiveness } = useSelector((state) => state.client.getOne);

  if (data.pulse_obj === undefined) {
    data.pulse_obj = {};
  }
  if (data.pulse_obj.period === undefined) {
    data.pulse_obj.period = {};
  }
  if (data.pulse_obj.period.frequencyType === undefined) {
    data.pulse_obj.period.frequencyType = surveySettingsConstants.WEEKS;
  }
  // Define Dispatch
  const dispatch = useDispatch();

  /**
   * Handle Change Event
   * @param {*} panel
   */
  const handleChange = (event) => {
    if (data._id && !data.isLoading && data.frequency) {
      let reducerData = getReducerUpdatedData(event, data);
      if (data.frequency === "Pulse" && event.name === "endDate") {
      if(new Date(event.value) <= new Date()){
        reducerData = getReducerUpdatedData({ name: "status", value: "Closed"}, reducerData)
      } else{
        reducerData = getReducerUpdatedData({ name: "status", value: "Open"}, reducerData) 
      }
      }
      if (event.name === "status" && event.value === "Closed" && new Date(data.endDate) > new Date()) {
        setOpenClosedStatusDialog(true)
        reducerData = getReducerUpdatedData({name: "endDate", value: new Date() }, reducerData)
      } else if (event.name === "frequency" && event.value === "Always on") {
        reducerData = getReducerUpdatedData({ name: "endDate", value: "" }, reducerData)
        reducerData = getReducerUpdatedData({ name: "status", value: "Open" }, reducerData)
        // Empty End Date String to avoid using previous date from any frequency
        reducerData = {...reducerData, endDateString: ""}
      }
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE, reducerData));
    }
  };

  // Handle Auto Complete
  const handleAutoCompolete = (e, n) => {
    handleChange({ name: n, value: e });
  };

  /**
   * Hanlde Pulse Changes
   * @param {*} e
   * @param {*} n
   */
  const handlePulse = (value, name) => {
    if (name === "frequency" && value === "Pulse") {
      let matqueslist = questions.questions.filter(item => item.qusType === "Matrix");
      let yesnoqueslist = questions.questions.filter(item => item.qusType === "Yes/No");
      if (matqueslist.length > 0 || yesnoqueslist.length > 0) {
        setOpenPulseMatrixDialog({ data: { name, value }, open: true });
      } else {
        handleChange({ name, value });
      }
    } else {
      handleChange({ name, value });
    }
  };

  /**
   * Hanlde Pulse Start Date Changes
   * @param {*} e
   * @param {*} n
   */
  const handlePulseStartDate = (event) => {
    if (event.value > 28) {
      setOpenPulseDateDialog(true);
      handleChange(event);
    } else {
      handleChange(event);
    }
  };

  /**
   * Handle Tab Change Event
   * @param {*} name
   */
  const handleTabChange = (panel) => (event, isExpanded) => {
    props.handleTabChange({ isExpanded, panel });
  };

  /**
 * Handle Dialogclose
 */
  const handleDialogCloseAction = () => {
    const prevFrequency = frequency;
    setFrequency(null);
    setOpenPulseMatrixDialog({ data: {}, open: false });
    handleChange({ name: "frequency", value: prevFrequency });
  };

  /**
 * Handle Pulse Date Dialog close
 */
  const handlePulseDateDialogAction = () => {
    setOpenPulseDateDialog(false);
  };

  /**
 * Handle Pulse Date Dialog Close
 */
  const handlePulseDateDialogCloseAction = () => {
    setOpenPulseDateDialog(false);
    handleChange({ name: "pulse_obj.period.startDate", value: "" });
  };

  /**
   * Handle Closed status Dialog Close Action
   */
  const handleClosedStatusDialogAction = () => {
    setOpenClosedStatusDialog(false)
  }

  const handlePulseCheckCloseAction = () => {
    setPulseCheck(false);
    setOpenPulseDateDialog(false);
    handleChange({ name: "pulse_obj.period.startDate", value: "" });
    handleChange({ name: "pulse_obj.period.startDay", value: "" });
  };

  /**
  * Handle Dialog Yes
  */
  const handleDialogYesAction = () => {
    handleChange(openPulseMatrixDialog.data);

    let newQuestions = questions.questions;
    newQuestions = newQuestions.filter(data => (data.qusType !== "Matrix" && data.qusType !== "Yes/No"));
    for (var i = 0; i < questions.catOrder.length; i++) {
      const catcheck = newQuestions.filter(data => data.category === questions.catOrder[i]);
      if (catcheck.length === 0) {
        questions.catOrder.splice(i, 1);
      }
    }
    questions.questions = newQuestions;
    let reducerData = getReducerUpdatedData({ name: "questions", value: newQuestions }, questions);
    reducerData = getReducerUpdatedData({ name: "catOrder", value: questions.catOrder }, questions);
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_REDUCER_UPDATE, reducerData));
    dispatch(surveyAction.saveQuestions(questions));
    setOpenPulseMatrixDialog({ data: {}, open: false });
  };

  // Check end date is greater than start date
  useEffect(() => {
    ValidatorForm.addValidationRule("isEnddategreater", (value) => {
      if (new Date(data.startDate).setHours(0, 0, 0, 0) >= new Date(data.endDate).setHours(0, 0, 0, 0)) {
        return false;
      } else {
        return true;
      }
    });
    if (data.type === surveyTypes.MULTI_RATER_FEEDBACK) {
      data.frequency = surveySettingsConstants.SURVEY_FREQUENCY[1]
    }

    if (data.frequency === "Pulse" && data.pulse_obj.period !== undefined && data.pulse_obj.period !== "" && data.endDate !== "") {
      // eslint-disable-next-line no-unused-vars
      let duration = Number(data.pulse_obj.period.frequency);
      const d = new Date();
      const currentday = d.getDay();
      const currentDate = d.getDate();
      const currentMonth = d.getMonth();
      const currentYear = d.getFullYear();
      if (data.pulse_obj.period.frequencyType !== surveySettingsConstants.WEEKS) {
        if (data.pulse_obj.period.startDate !== undefined && data.pulse_obj.period.startDate !== "") {
          const startdate = Number(data.pulse_obj.period.startDate);
          let firstPulseStartDate = currentYear + "-" + (currentMonth + 1) + "-" + startdate;
          let firstPulseEndDate = new Date(firstPulseStartDate)
          firstPulseEndDate.setDate(firstPulseEndDate.getDate() + (duration * 30))
          if ((new Date(firstPulseStartDate) > new Date(data.endDate) || new Date(firstPulseEndDate) > new Date(data.endDate)) && data.status !== "Closed") {
            setPulseCheck(true);
          } else {
            setPulseCheck(false);
          }
        } else {
          setPulseCheck(false);
        }
      } else {
        if (data.pulse_obj.period.startDay !== undefined && data.pulse_obj.period.startDay !== "") {
          const startday = surveySettingsConstants.WEEK_DAYS_CHECK[data.pulse_obj.period.startDay];
          const pulseStartDay = currentday - startday;
          let firstPulseStartDate = currentYear + "-" + (currentMonth + 1) + "-" + (currentDate - pulseStartDay);
          let firstPulseEndDate = new Date(firstPulseStartDate)
          firstPulseEndDate.setDate(firstPulseEndDate.getDate() + (duration * 7))

          if ((new Date(firstPulseStartDate) > new Date(data.endDate) || new Date(firstPulseEndDate) > new Date(data.endDate)) && data.status !== "Closed" ) {
            setPulseCheck(true);
          } else {
            setPulseCheck(false);
          }
        }
      }
    }

    const sDate = moment(data.startDate).format('MM/DD/YYYY');
    const eDate = moment(data.endDate).format('MM/DD/YYYY');
    const curDate = moment().format('MM/DD/YYYY');

    if (data.frequency !== "Always on" && data.startDate !== undefined && data.startDate !== "" && data.endDate !== undefined && data.endDate !== "") {
      const getendDate = new Date(data.endDate);
      const getstartDate = new Date(data.startDate);
      const getpresentdate = new Date();

      const date1 = `${getendDate.getDate()}${("0" + (getendDate.getMonth() + 1)).slice(-2)}${getendDate.getFullYear()}`;
      const date2 = `${getpresentdate.getDate()}${("0" + (getpresentdate.getMonth() + 1)).slice(-2)}${getpresentdate.getFullYear()}`;
      const date3 = `${getstartDate.getDate()}${("0" + (getstartDate.getMonth() + 1)).slice(-2)}${getstartDate.getFullYear()}`;

      if (new Date(sDate) > new Date(curDate) && data.status !== "Pending") {
        handleChange({ name: "status", value: "Pending" });
      } else if (new Date(sDate) <= new Date(curDate) && (new Date(eDate) >= new Date(curDate) || date1 === date2) && data.status !== "Open" && data.frequency !== "Pulse") {
        handleChange({ name: "status", value: "Open" });
      // } else if (new Date(eDate) < new Date(curDate) && date1 !== date2 && data.status !== "Closed" && new Date(sDate) < new Date(curDate) && Number(date3) < Number(date2)) {
      } else if (new Date(eDate) < new Date(curDate) && date1 !== date2 && data.status !== "Closed" && new Date(sDate) < new Date(curDate)) {
        handleChange({ name: "status", value: "Closed" });
      }
    } else if (data.frequency === "Always on" && data.startDate !== undefined && data.startDate !== "") {
      if (new Date(sDate) > new Date(curDate) && data.status !== "Pending" && data.status !== "Closed") {
        handleChange({ name: "status", value: "Pending" });
      } else if (data.status !== "Open" && new Date(sDate) <= new Date(curDate) && data.status !== "Closed") {
        handleChange({ name: "status", value: "Open" });
      }
    }
    setFrequency(data.frequency);
  }, [data]);

  return (
    <Accordion
      className={`mb-2 pe-accordion ${panelError ? "panel-error" : ""}`}
      expanded={expanded === "setup-panel"}
      onChange={handleTabChange("setup-panel")}
    >
      {/*
        panel header summary
      */}

      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expandicon" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <SvgIcon className={`${classes.settings_icons} ${expanded === "setup-panel" ? "panel-active" : "panel-inactive"} `} xmlns="http://www.w3.org/2000/svg" width="27.728" height="16.17" viewBox="0 0 15.728 16.17">
          {SettingAccord()}
        </SvgIcon>
        <TypographyComponent
          variant={"h5"}
          className={` ${classes.setting_accordheading} ${expanded === "setup-panel" ? "panel-active" : ""} `}
        >{surveySettingsConstants.SETUP_PANEL_TITLE}</TypographyComponent>
        <TypographyComponent
          variant={"h6"}
          className={`${"oneline_ellipse mt2"} ${expanded === "setup-panel" ? "panel-active" : ""}`}
        >{data.type === surveyTypes.MULTI_RATER_FEEDBACK ? surveySettingsConstants.SETUP_PANEL_MESSAGE_ME : surveySettingsConstants.SETUP_PANEL_MESSAGE}</TypographyComponent>
      </AccordionSummary>

      {/*
        panel body
      */}
      {data &&
        <AccordionDetails>
          <Dialog
            open={openClosedStatusDialog}
            onClose={handleClosedStatusDialogAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              {surveySettingsConstants.CLOSED_STATUS_DIALOG_MESSAGE}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" disableElevation size="small" onClick={handleClosedStatusDialogAction} color="primary" autoFocus>
                {surveyConstants.OK_BUTTON}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openPulseDateDialog}
            onClose={handlePulseDateDialogAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              {surveySettingsConstants.PULSE_DATE_DIALOG_MESSAGE}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" disableElevation size="small" onClick={handlePulseDateDialogAction} color="primary" autoFocus>
                {surveyConstants.OK_BUTTON}
              </Button>
              <Button variant="contained" disableElevation size="small" onClick={handlePulseDateDialogCloseAction} color="secondary" >
                {surveyConstants.CANCEL_BUTTON}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={pulseCheck}
            onClose={handlePulseCheckCloseAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              {errorsConstants.pulsevalidation}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" disableElevation size="small" onClick={handlePulseCheckCloseAction} color="primary" >
                {surveyConstants.CLOSE_BUTTON}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openPulseMatrixDialog.open}
            onClose={handleDialogCloseAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              {surveySettingsConstants.REMOVE_MATRIX_YESNO_PULSE}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" disableElevation size="small" onClick={handleDialogYesAction} color="primary" autoFocus>
                {surveyConstants.YES_BUTTON}
              </Button>
              <Button variant="contained" disableElevation size="small" onClick={handleDialogCloseAction} color="secondary" >
                {surveyConstants.NO_BUTTON}
              </Button>
            </DialogActions>
          </Dialog>
          <Grid container spacing={4} className={`${classes.setting_expandcontainer}`}>
            <Grid item md={3} xs={12}>
              <TypographyComponent
                variant="h6" >{surveySettingsConstants.SELECT_FREQUENCY_TYPE}</TypographyComponent>
              <AutoCompleteComponent
                panel={panel}
                name="frequency"
                suggestions={data.type === surveyTypes.MULTI_RATER_FEEDBACK ? surveySettingsConstants.SURVEY_FREQUENCY_POINTINTIME : surveySettingsConstants.SURVEY_FREQUENCY}
                handleAutoCompolete={(e, n) => handlePulse(e, n)}
                suggestionType="array"
                value={frequency || ""}
                validators={["required"]}
                className={"heightone"}
                errorMessages={[errorsConstants.required]}
              ></AutoCompleteComponent>
            </Grid>
            <Grid item md={3} xs={12} className={"relative"}>
              <TypographyComponent
                variant="h6" >{surveySettingsConstants.SELECT_START_DATE}</TypographyComponent>
              <DatePickerComponent
                panel={panel}
                name="startDate"
                value={data.startDate}
                className={classes.textField}
                handleChange={(e) => handleChange(e)}
                inputVariant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                disabled={false}
                validators={["required"]}
                errorMessages={[errorsConstants.required]}
              />
            </Grid>

            {/*
            Hides when selected frequency is always on
            */}

            {data.frequency !== surveySettingsConstants.SURVEY_FREQUENCY_ALWAYSON &&
              <Grid item md={3} xs={12} className={"relative"}>
                <TypographyComponent variant="h6"
                >{surveySettingsConstants.SELECT_END_DATE}
                </TypographyComponent>
                <DatePickerComponent
                  panel={panel}
                  name="endDate"
                  value={data.endDate}
                  className={classes.textField}
                  handleChange={(e) => handleChange(e)}
                  inputVariant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  disabled={false}
                  validators={["required", "isEnddategreater"]}
                  errorMessages={[errorsConstants.required, errorsConstants.isEnddategreater]}
                />
              </Grid>
            }
            <Grid item md={3} xs={12}>
              <TypographyComponent variant="h6">
                {surveySettingsConstants.SURVEY_STATUS_FIELD}
                <InformationComponent>
                  <TypographyComponent variant="tooltipbody">
                    {surveySettingsConstants.SURVEY_STATUS_INFO}
                  </TypographyComponent>
                </InformationComponent>
              </TypographyComponent>
              <AutoCompleteComponent
                name="status"
                suggestionType="array"
                className={"heightone"}
                suggestions={surveySettingsConstants.SURVEY_STATUS}
                handleAutoCompolete={(e, n) => handleAutoCompolete(e, n)}
                value={data.status || ""}
              ></AutoCompleteComponent>
            </Grid>

            {/*
            Displays when selected frequency is pulse
            */}

            {data.frequency === surveySettingsConstants.SURVEY_FREQUENCY_PULSE &&
              <Grid item md={3} xs={12}>
                <TypographyComponent variant="h6">
                  {surveySettingsConstants.SURVEY_PULSE_TO_BEGIN}
                </TypographyComponent>
                <Grid container alignItems="center">
                  <Grid item md={6} xs={6}>
                    <TextFieldComponent id="pulse_obj.period.frequency"
                      type="Number"
                      name="pulse_obj.period.frequency"
                      size={"small"}
                      min='1'
                      fullWidth={true}
                      variant="outlined"
                      value={data.pulse_obj ? (data.pulse_obj.period ? data.pulse_obj.period.frequency : "") : ""}
                      handleChange={(e) => handleChange(e)}
                      validators={['onlyNumberCanEmpty', 'required']}
                      InputProps={{
                        onKeyDown: (e) => {
                          const isNonNumeric = (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105);
                          const allowedKeyCodes = [8, 46];
                          if (!isNonNumeric && allowedKeyCodes.indexOf(e.keyCode) === -1) {
                            e.preventDefault();
                          }
                        }
                      }}
                      className={`${classes.settings_input} ${"rtnoborder"}  ${classes.nowarpValidationTxt} pulseTextBox`}
                      style={{ height: "32px !important" }}
                      panel={panel} />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <AutoCompleteComponent
                      name="pulse_obj.period.frequencyType"
                      suggestionType="array"
                      suggestions={surveySettingsConstants.PULSE_FREQUENCYTYPE}
                      handleAutoCompolete={(e, n) => handleAutoCompolete(e, n)}
                      value={data.pulse_obj ? (data.pulse_obj.period ? data.pulse_obj.period.frequencyType : surveySettingsConstants.WEEKS) : surveySettingsConstants.WEEKS}
                      className={`${"ltnoborder"}`}
                      panel={panel}
                    ></AutoCompleteComponent>
                  </Grid>
                </Grid>
              </Grid>
            }
            {data.frequency === surveySettingsConstants.SURVEY_FREQUENCY_PULSE && data.pulse_obj.period.frequencyType !== surveySettingsConstants.WEEKS &&
              <Grid item md={3} xs={12}>
                <TypographyComponent variant="h6"
                >{surveySettingsConstants.START_NEXT_PULSE}</TypographyComponent>
                <Grid container alignItems="center">
                  <Grid item sm={3} xs={4}
                    className={classes.settings_inlinelabel}>
                    <TypographyComponent variant="h6"
                    >{surveySettingsConstants.DATE}</TypographyComponent>
                  </Grid>
                  <Grid item sm={9} xs={8} >
                    <SelectFieldComponent
                      name="pulse_obj.period.startDate"
                      variant="outlined"
                      panel={panel}
                      value={data.pulse_obj ? (data.pulse_obj.period ? data.pulse_obj.period.startDate : "") : ""}
                      fullWidth={true}
                      options={surveySettingsConstants.MONTH_DATE}
                      handleChange={(e) => handlePulseStartDate(e)}
                      validators={["required"]}
                    >
                    </SelectFieldComponent>
                  </Grid>
                </Grid>
              </Grid>
            }
            {data.frequency === surveySettingsConstants.SURVEY_FREQUENCY_PULSE && data.pulse_obj.period.frequencyType !== surveySettingsConstants.WEEKS &&
              <Grid item md={12} xs={12}>
                <TypographyComponent variant="subtitle2" className={`${classes.colorred}`}
                >{surveySettingsConstants.PULSE_MONTH_TEXT_TO_DISP}
                </TypographyComponent>
              </Grid>
            }
            {data.frequency === surveySettingsConstants.SURVEY_FREQUENCY_PULSE && data.pulse_obj.period.frequencyType === surveySettingsConstants.WEEKS &&
              <Grid item md={3} xs={12}>
                <TypographyComponent variant="h6"
                >{surveySettingsConstants.START_NEXT_PULSE}</TypographyComponent>
                <Grid container alignItems="center">
                  <Grid item sm={3} xs={4}
                    className={classes.settings_inlinelabel}>
                    <TypographyComponent variant="h6"
                    >{surveySettingsConstants.DAY}</TypographyComponent>
                  </Grid>
                  <Grid item sm={9} xs={8} >
                    <AutoCompleteComponent
                      name="pulse_obj.period.startDay"
                      suggestionType="array"
                      suggestions={surveySettingsConstants.WEEK_DAYS}
                      handleAutoCompolete={(e, n) => handleAutoCompolete(e, n)}
                      value={data.pulse_obj ? (data.pulse_obj.period ? data.pulse_obj.period.startDay : "") : ""}
                      validators={["required"]}
                      panel={panel}
                    ></AutoCompleteComponent>
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        </AccordionDetails>
      }
    </Accordion>
  );
}

// default props
SetupPanel.defaultProps = {
  classes: {},
  data: {},
  questions: [],
  expanded: false,
  handleTabChange: () => { }
};

// prop types
SetupPanel.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  questions: PropTypes.array,
  expanded: PropTypes.any,
  handleTabChange: PropTypes.func
};

export default (SetupPanel);
