import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Link } from "react-router-dom";

// Import Styles and Image
import NewReportStyles from "./NewReportStyles";


// Import Helpers
import { addEllipsis } from "../../../../helpers";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import SearchInputComponent from "../../../../components/SearchInputCompoment/SearchInputComponent";

// import constants
import { FilterConstants, ReportsConstants, surveyTypes } from "../../../../config/constants";

// Import images
import PulseImg from "../../../../assets/images/svg/pulseicon.svg";

//Import Actions
import { reportAction, alertAction, updateStateReducerAction } from "../../../../redux/actions";
import SaveReportName from "./NewReportName"

function Survey({ classes }) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [originalSurveys, setOriginalSurveys] = useState([]); // New state to store original surveys
  const { allSurveys, customReportData, initCustomReportData } = useSelector((state) => state.report);
  const { surveys, date } = customReportData;
  const pulseFound = (surveys || [])?.some(survey => survey.frequency === "Pulse");
  const mesurveys = (allSurveys || [])?.filter(data => data.type === surveyTypes.MULTI_RATER_FEEDBACK);
  const pulseSurveys = (allSurveys || [])?.filter(data => data.frequency === "Pulse");


  const handleSearch = (name, value) => {
    value = value || '';
    let searchSurveys = originalSurveys?.filter(obj => obj.name && obj.name.toLowerCase().includes(value.toLowerCase()));
    const data = { data: searchSurveys };
    dispatch(updateStateReducerAction.update(ReportsConstants.GET_SURVEYS_SUCCESS, data));
    setSearch(value);
  };


  const handleClearSearch = () => {
    const data = { data: originalSurveys }; // Use originalSurveys instead of allSurveys
    dispatch(updateStateReducerAction.update(ReportsConstants.GET_SURVEYS_SUCCESS, data));
    setSearch('');
  };


  const getCheckedData = (id) => surveys?.some(survey => survey._id === id);

  const updateSurveys = (newSurvey) => {
    const isPulse = surveys?.some(survey => survey.frequency === "Pulse");
    const isNonPulse = surveys?.some(survey => survey.frequency !== "Pulse");
    const isNonRater = surveys?.some(survey => survey.type !== surveyTypes.MULTI_RATER_FEEDBACK);
    const isMultiRater = surveys?.some(survey => survey.type === surveyTypes.MULTI_RATER_FEEDBACK);

    if ((isMultiRater && newSurvey.type !== surveyTypes.MULTI_RATER_FEEDBACK) ||
      (isNonRater && newSurvey.type === surveyTypes.MULTI_RATER_FEEDBACK)) {
      dispatch(alertAction.error(FilterConstants.COMBINE_ME_ALERT));
      return;
    }

    if ((isPulse && newSurvey.frequency !== "Pulse") ||
      (isNonPulse && newSurvey.frequency === "Pulse")) {
      dispatch(alertAction.error(FilterConstants.COMBINE_ALERT));
      return;
    }

    let updatedSurveys = surveys?.some(s => s._id === newSurvey._id)
      ? surveys?.filter(s => s._id !== newSurvey._id)
      : [...surveys, newSurvey];

    const updatedReportData = {
      ...customReportData,
      surveys: updatedSurveys, hideDashWidgets: initCustomReportData.hideDashWidgets, includeDashboard: false, date: initCustomReportData.date, is_sentiment: initCustomReportData.is_sentiment, is_verbatim: initCustomReportData.is_verbatim, commentQuestions: initCustomReportData.commentQuestions,
    };
    dispatch(reportAction.updateCustomReport(updatedReportData));
  };


  const handleClear = () => {
    const updatedReportData = {
      ...customReportData,
      surveys: [],
    };
    dispatch(reportAction.updateCustomReport(updatedReportData));
  };

  const renderSurveyCheckbox = (surveydata) => {
    if (surveydata && surveydata?.length > 0) {
      let checkboxJSX = [];
      surveydata?.map((data) => {
        if (data.frequency !== "Pulse" && data.type !== surveyTypes.MULTI_RATER_FEEDBACK) {
          checkboxJSX.push(<Grid item xs={12} key={"div_" + data._id} className={classes.demographyCheckbox}>
            <FormControlLabel
              control={
                <Checkbox
                  key={data._id}
                  id={data._id}
                  name={data.name}
                  color="primary"
                  checked={getCheckedData(data._id)}
                  onChange={e => updateSurveys({
                    name: e.target.name,
                    _id: e.target.id,
                    frequency: data.frequency,
                    type: data.type,
                    hasRehireBaseQues: data.hasRehireBaseQues,
                    hasComplianceBaseQues: data.hasComplianceBaseQues,
                    startDate: data.startDate,
                    endDate: data.endDate
                  })}
                />
              }
              label={data.name}
            />
          </Grid>)
        }
      })
      surveydata?.map((data) => {
        if (data.frequency === "Pulse") {
          checkboxJSX.push(<Grid item xs={12} direction="row" key={"div_" + data._id} className={classes.demographyCheckbox} style={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              control={
                <Checkbox
                  key={data._id}
                  id={data._id}
                  name={data.name}
                  color="primary"
                  checked={getCheckedData(data._id)}
                  onChange={e => updateSurveys({ name: e.target.name, _id: e.target.id, frequency: data.frequency, pulse_obj: data.pulse_obj, type: data.type })}
                />
              }
              label={data.name}
            />
            <img className={`${classes.expansionPlus} ${"expansionPlus pl-1"}`} src={PulseImg}></img>
          </Grid>)
        }
      })

      return checkboxJSX.sort((a, b) => {
        let elemB, elemA;
        if (a.props.children.length > 0) {
          elemA = a.props.children[0]
        } else {
          elemA = a.props.children
        }
        if (b.props.children.length > 0) {
          elemB = b.props.children[0]
        } else {
          elemB = b.props.children
        }
        return elemB.props.control.props.checked - elemA.props.control.props.checked
      })
    } else {
      return (
        <Grid item xs={12} className={"mt-1"}>
          <TypographyComponent title='No Surveys Found!' variant='inherit' />
        </Grid>
      )
    }
  }




  useEffect(() => {
    dispatch(reportAction.getSurveys("", (getSurveysRes) => {
      setOriginalSurveys(getSurveysRes);
    }));
  }, []);

  const regularSurveys = allSurveys?.filter(
    survey => survey.frequency !== "Pulse" && survey.type !== surveyTypes.MULTI_RATER_FEEDBACK
  );

  const surveydata = [...regularSurveys, ...pulseSurveys]?.sort((a, b) => (a.endDate > b.endDate) ? -1 : 1)


  return (
    <React.Fragment>
    <Grid container style={{ maxHeight: "100%" }}>
      <Grid container item sm={12} style={{ paddingBottom: "30px" }}>
        <SaveReportName />
      </Grid>

      {originalSurveys.length > 0 &&
        <>

          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <TypographyComponent variant="h6" className="txtBold">
                <b>{ReportsConstants.CUSTOM_SURVEY_SELECT}</b>
              </TypographyComponent>
            </Grid>
            <Grid item>
              <Link className="cursor-pointer colorTheme right" onClick={handleClear}>
                {ReportsConstants.CUSTOM_CLEAR_ALL}
              </Link>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sm={12}>
              <Grid container>
                <Grid item sm={12}>
                  <SearchInputComponent
                    id="searchCategoryTex"
                    placeholder="Search"
                    value={search}
                    field="searchCategory"
                    showClearIcon={true}
                    fullWidth={true}
                    className="mt-1 mb-1"
                    onInputChangeRequest={handleSearch}
                    handleClearSearch={handleClearSearch}
                    onEnter={() => { }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.mainContainer}>
            <Grid item sm={20} >
              {renderSurveyCheckbox(surveydata)}
            </Grid>
          </Grid>
        </>

      }
    </Grid>
    </React.Fragment>
  );
}

Survey.defaultProps = {
  classes: {},
};

Survey.propTypes = {
  classes: PropTypes.object,
  isSaveReport: PropTypes.bool
};

export default withStyles(NewReportStyles)(Survey);