import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import TakeSurveyStyles from "../TakeSurveyStyles";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import ErrMsgComponent from "./QuestionErrors/ErrMsgComponent";

//import Constants
import { surveyQuestionsConstants } from "../../../../config/constants";

function YesorNo(props) {
  // Get Props Values
  const { classes, langCode, item, surveyQuesAnswer, errorStatus, questionIndex, continueBtn, changeValue, continueBtn2, changeValue2} = props;
  const { ansSet, text, isRequired } = item;
  const [ansYes, setAnsYes] = useState(surveyQuesAnswer === "" ? null : surveyQuesAnswer);
  const [clickIndex, setClickIndex] = useState("")
  const handleContinueChange = () => {
    changeValue(false);
  };
  const handleTabChange = () => {
    changeValue2(false);
  };
  /* Todo: Have to check yes/no component  */
  const handleAnswerUpdate = (val, index) => {
    let value = val;
    value = ansYes != val ? val : null;
    setAnsYes(value);
    handleContinueChange();
    handleTabChange();
    setClickIndex(index)
    props.handleAnswerUpdate(value);
  };

  const questionText = text[langCode];
  const ansArr = ansSet[langCode];
  const answerSet = Object.values(ansArr);
  return (
    <React.Fragment>
      {/* YESNO */}
      <Grid container alignItems="center" className={classes.yesNoSingleQuestion}>
        <Grid item md={6} xs={12} className="pb5">
          <TypographyComponent variant="body1" extraProps={{ tabIndex: "0" }}>
            {questionIndex}. {questionText} {isRequired ? "*" : ""}
          </TypographyComponent>
          {continueBtn || continueBtn2 ? errorStatus ?  <ErrMsgComponent /> : null : questionIndex === clickIndex && errorStatus ? <ErrMsgComponent />: null}
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container alignItems="center" justifyContent="flex-end">
            <ButtonComponent
              pageClassName={ansYes === 0 ? classes.yesNoActive : ""}
              title={answerSet[0]}
              handleClick={(e) => {
                handleAnswerUpdate(0, questionIndex);
              }}
            ></ButtonComponent>

            <ButtonComponent
              pageClassName={ansYes === 1 ? classes.yesNoActive : ""}
              title={answerSet[1]}
              handleClick={(e) => {
                handleAnswerUpdate(1, questionIndex);
              }}
            ></ButtonComponent>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
// default props
YesorNo.defaultProps = {
  classes: {},
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE,
  handleAnswerUpdate: () => {},
  errorStatus: false,
  continueBtn : false
};

// prop types
YesorNo.propTypes = {
  classes: PropTypes.object,
  langCode: PropTypes.string,
  handleAnswerUpdate: PropTypes.func,
  errorStatus: PropTypes.bool,
  continueBtn: PropTypes.bool
};
export default withStyles(TakeSurveyStyles)(YesorNo);
