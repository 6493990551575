import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Radio from "@mui/material/Radio";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// Import Styles and Image
import SurveyPreviewStyles from "../../SurveyPreviewStyles";

// Import Component
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import ErrMsgComponent from "./QuestionErrors/ErrMsgComponent";

//import Constants
import { surveyQuestionsConstants } from "../../../../../config/constants";

function Matrix(props) {
  // Get Props Values
  const {
    classes,
    langCode,
    item,
    surveyQuesAnswer,
    errorStatus,
    questionIndex,
    continueBtn,
    changeValue
  } = props;

  const selectedValue = surveyQuesAnswer ? surveyQuesAnswer : [];

  /* const [selectedValue, setSelectedValue] = useState(
    surveyQuesAnswer ? surveyQuesAnswer : []
  ); */
  const [clickIndex, setClickIndex] = useState("");
  const handleContinueChange = () => {
    changeValue(false);
  };
  
  const handleChange = (event, index) => {
    const question = event.target.name;
    const answer = event.target.value;
    /* const ansObj = { subques: question, answer:parseInt( answer) };
    const oldSelected = selectedValue.filter(
      (data, key) => data.subques != question
    );
    const matrixAnswers = [ansObj, ...oldSelected];
    setSelectedValue(matrixAnswers); */

    const ansObj = { [question]: parseInt(answer) };
    const oldSelected = selectedValue.filter((data, key) => {
      return !data.hasOwnProperty(question);
    });
    const matrixAnswers = [ansObj, ...oldSelected];
    handleContinueChange();
    setClickIndex(index)
    // setSelectedValue(matrixAnswers);
    props.handleAnswerUpdate(matrixAnswers);
  };

  const handleOnClick = (e, index) => {
    const curQus = e.target.name;
    const curAns = e.target.value;
    handleContinueChange();
    setClickIndex(index)
    if (selectedValue.length > 0) {
      const newAnsSet = selectedValue.filter((data, key) => {
        return data[curQus] != curAns;
      });
    
      // setSelectedValue(newAnsSet);
      props.handleAnswerUpdate(newAnsSet);
    }
  };

  const { ansSet, text, subQues, isRequired } = item;
  const questionText = text[langCode];
  const ansSetArr = ansSet[langCode];
  const answerSet = Object.values(ansSetArr);

  return (
    <React.Fragment>
      {/* Matrix */}
      <Grid container className="mt-2 mb-2">
        <Grid item xs={12} className="pb-1">
          <TypographyComponent variant="body1">
            {questionIndex}. {questionText} {isRequired ? "*" : ""}
          </TypographyComponent>
          {continueBtn ? errorStatus ?  <ErrMsgComponent /> : null : questionIndex === clickIndex && errorStatus ? <ErrMsgComponent />: null}
        </Grid>

        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="simple table"
          >
            <TableHead className={classes.matricTabHeader}>
              <TableRow>
                <TableCell component="th" scope="row"></TableCell>
                {answerSet.map((ans, anskey) => (
                  <TableCell align="center" key={anskey}>
                    {ans}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {subQues.map((ques, qkey) => {
                let selectedAns = {};
                if (selectedValue.length > 0) {
                  selectedAns = selectedValue.filter((sel, k) =>
                    sel.hasOwnProperty(ques.id)
                  )[0];
                }
                return (
                  <TableRow className="singleQuestionMatrix" key={qkey}>
                    <TableCell component="th" scope="row">
                      {ques.text[langCode]}
                    </TableCell>
                    {answerSet.map((ans, anskey) => {
                      const ansStatus =
                        selectedAns && selectedAns[ques.id] == anskey
                          ? true
                          : false;
                      return (
                        <TableCell align="center" key={anskey}>
                          <Radio
                            checked={ansStatus}
                            onChange={(event)=>handleChange(event, questionIndex)}
                            onClick={(e)=>handleOnClick(e, questionIndex)}
                            value={anskey}
                            name={ques.id}
                            id={`${ques.id}_${anskey}`}
                            inputProps={{
                              "aria-label": `${ques.id}_${anskey}`,
                            }}
                            className={classes.subQuestionChoicesRadio}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </React.Fragment>
  );
}
// default props
Matrix.defaultProps = {
  classes: {},
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE,
  handleAnswerUpdate: () => {},
  errorStatus: false,
  continueBtn : false,
  continueBtn2 : false
};

// prop types
Matrix.propTypes = {
  classes: PropTypes.object,
  langCode: PropTypes.string,
  handleAnswerUpdate: PropTypes.func,
  errorStatus: PropTypes.bool,
  continueBtn : PropTypes.bool,
  continueBtn2: PropTypes.bool
};
export default withStyles(SurveyPreviewStyles)(Matrix);
