import React, { useState, useEffect } from 'react';
import { Grid, Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import RepeatGrid from "../../../../assets/images/svg/repeat-grid.svg";
import { withStyles } from '@mui/styles';
import ReportsStyles from "../../ReportsStyles";
import { useDispatch, useSelector } from "react-redux";
import { reportAction } from "../../../../redux/actions";
// Constants
import { ReportsConstants } from "../../../../config/constants";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";


const CHARTS = {
  '0': 'Summary',
  '1': 'Comment Analysis',
  '2': 'Yes/No Question',
  '3': 'Matrix Questions',
  '4': 'Hotspot',
  '5': 'Engagement',
  '6': 'Trends Over Time'
};

const DragHandle = () => (
  // <Tooltip arrow title="Reorder Icon">
  <img
    src={RepeatGrid}
    className="cursor-move icon-hover"
    alt="Menu"
    draggable={false}
  />
);

const SortableTitles = (props) => {
  const {customReportData, commentQuestions}  = useSelector((state) => state.report);
  let { chartOrder = [] } = customReportData || {};
  const [titles, setTitles] = useState([]);
  const { classes } = props;
  const dispatch = useDispatch();

  const getTopicName = (title) => {
    // Check if the title is a key in CHARTS
    if (CHARTS[title]) {
      return CHARTS[title];
    }

    // If not in CHARTS, return the title as-is
    return title;
  };

  useEffect(() => {
    if (chartOrder && chartOrder?.length > 0) {
      if(commentQuestions?.length === 0){
        chartOrder=chartOrder?.filter((item)=>!item.includes("Sentiment") || !item.includes("Verbatim"))
      }
      const updatedReportData = {
        ...customReportData,
        charts: chartOrder,
      };
      dispatch(reportAction.updateCustomReport(updatedReportData));
    }
  }, [])

  // Initialize titles from chartOrder when component mounts or chartOrder changes
  useEffect(() => {
    if (chartOrder && chartOrder?.length > 0) {

      const formattedTitles = (chartOrder || [])?.map((title, index) => ({
        id: index + 1,
        TopicName: getTopicName(title)
      }));
      setTitles(formattedTitles);
    }
  }, [chartOrder])

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    const dragIndex = Number(e.dataTransfer.getData('text/plain'));
    const newTitles = [...titles];
    const draggedItem = newTitles[dragIndex];

    newTitles.splice(dragIndex, 1);
    newTitles.splice(dropIndex, 0, draggedItem);

    setTitles(newTitles);

    // Update the chartOrder in Redux store
    const newChartOrder =
      newTitles?.map(title => {
        const QuestionKey = Object.keys(CHARTS)?.find(
          key => CHARTS[key] === title.TopicName
        );
        return title.TopicName

      });

    const updatedReportData = {
      ...customReportData,
      chartOrder: newChartOrder,
    };
    dispatch(reportAction.updateCustomReport(updatedReportData));
  };

  return (
    <Grid container>
      <Grid container style={{ justifyContent: 'left' }}>
        <Grid container className={classes.lightOrangeBox}>
          <Box>
            <p>
              {ReportsConstants.CUSTOM_ORGANIZE}
              <InformationComponent>
                <TypographyComponent variant="tooltipbody">
                  {ReportsConstants.CUSTOM_ORGANIZE_NOTE}
                </TypographyComponent>
              </InformationComponent>
            </p>

          </Box>
        </Grid>
      </Grid>

      <Grid container>
        {(titles || [])?.map((item, index) => (
          <Grid
            key={item.id}
            item
            xs={12}
            className="action_alert_item dflex mb-2 bg-white rounded-lg shadow-lg border border-gray-200 py-20"
            draggable={true}
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
            style={{ paddingTop: "10px", paddingBottom: "10px", boxShadow: "-1px 1px 15px -7px rgba(0, 0, 0, 0.2)" }}
          >
            <Grid item className="dflex pl-15 pr-15 mt8 mb4">
              <DragHandle />
            </Grid>
            <Grid item className="pl-1 pr-2 selfcenter">
              <Badge
                color="secondary"
                className="badgebig"
                badgeContent={index + 1}
              />
            </Grid>
            <Grid item sm={12} className="pl5 dflex spacebetween">
              <Grid item className="selfcenter">
                {item.TopicName}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default withStyles(ReportsStyles)(SortableTitles);