import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
//Import socket
import socketIOClient from 'socket.io-client';

// Import Actions
import {
  profileAction, metadataAction, clientAction,
  adminsAction, filterAction, alertAction, fileManagerAction, meSurveyAction
} from "../redux/actions";

// Import Private Layout
import PrivateLayout from "../layouts/PrivateLayout/PrivateLayout";

// Import Setup Preference Page
import PageSetUpPreference from "../containers/PageSetupPreference/PageSetUpPreference";

// Import Helper Function
import { getToken } from "../helpers/appHelper";
import { excelConstants } from "../config/constants";

// Import Page URL Access File
import { checkPageAccess } from "../config/pageAccess"

// Import Table Size
import { getTableRowSize } from "../config/layout";

// helpers
import { _api } from '../config/environment'
import _ from "lodash";

let notifications = []
let notify_interval = null

const PrivateRoute = ({ component: Component, ...rest }) => {

  // Define Dispatch and State
  const dispatch = useDispatch();

  // Get Logged In User Tocken
  const isLoggedIn = getToken();

  // get user id for socket connection
  // Get Logged in User from redux
  const user = useSelector((state) => state.profile.user);
  let { surveys } = useSelector(state => state.admin.preference)
  const { allSurveys } = useSelector(state => state.filter)

  // Get Page Access
  let access = null;
  let user_id = null
  if (user && user._id) {
    access = checkPageAccess(user, rest);
    user_id = user._id
  }

  // Check for preference calculated correct, if not will calculate again
  useEffect(() => {
    const preferenceSurveysIds = _.compact(_.map(surveys, '_id'));
    const allSurveyIds = _.compact(_.map(allSurveys, '_id'));

    if (
      (Array.isArray(preferenceSurveysIds) && preferenceSurveysIds.length > 0) &&
      (Array.isArray(allSurveyIds) && allSurveyIds.length > 0)
    ) {
      const isPreferenceValid = _.filter(preferenceSurveysIds, function(item) {
        return _.includes(allSurveyIds, item);
      });
  
      if (Array.isArray(isPreferenceValid) && isPreferenceValid.length === 0) {
        dispatch(adminsAction.getReportPreference(() => {}, true, {skipValidation: true}));
      }
    }
  }, [surveys, allSurveys]);


  // Get Logged In User Details
  useEffect(async() => {
    const fetchclientMetadata = async() => {
      await Promise.all([
        dispatch(metadataAction.getClientMetadata()),
        dispatch(metadataAction.getMetadataWithRGI())
      ]);
    }

    const fetchNonClientMetada = async() => {
      await Promise.all([
        dispatch(clientAction.getIndustry()),
        dispatch(filterAction.getAllSurveys()),
        dispatch(profileAction.user())
      ]);
    }

    dispatch(adminsAction.getReportPreference(async () => {
      await fetchclientMetadata();
      await fetchNonClientMetada();
    },true, { hideCharts: ['1'] }));
  }, [dispatch]);

  // socket listener
  useEffect(() => {
    notifications = []
    clearTimeout(notify_interval)
    if (user_id) {
      // init connection 
      window.socketIO = socketIOClient.connect(_api.socket_url, {
        transports: ['websocket'],
        auth: {
          token: `Bearer ${getToken()}`,
          user_id: user_id
        }
      })
      window.socketIO.on('notifications', ({ message, type, data_id, duration = excelConstants.EXPORT_ALERT_TEXT_DEFAULT_DURATION, module }) => {
        notifications.push({ message, type, data_id, duration, module })
      })

      window.socketIO.on('process_notifications', ({ message, type, data_id, module,
        survey_id, duration = excelConstants.EXPORT_ALERT_TEXT_DEFAULT_DURATION }) => {
        console.log(message)
        notifications.push({ message, type, data_id, duration, module, survey_id })
      })

      window.socketIO.on('fileManagerList', () => {
        let defaultSearchData = { getAll: true }
        // if in participants exports page change search query
        if (window.location.href.indexOf('participants/exports') >= 0) {
          defaultSearchData = {
            search: {
              type: "Export",
              module: excelConstants.PARTICIPANT_DOWNLOAD
            }
          }
        }
        dispatch(fileManagerAction.getList(1, getTableRowSize(), "cdt", "desc", [], [], defaultSearchData))
      })
      window.socketIO.on('disconnect', () => {
        console.log("disconnected")
      })

      // display messages after 3 seconds delay
      startNotify()
    }
  }, [user_id])


  const startNotify = () => {
    clearTimeout(notify_interval)
    showNotification()
    notify_interval = setTimeout(() => {
      startNotify()
    }, 3000)
  }


  const showNotification = () => {
    if (notifications.length) {
      const data = notifications.pop()
      if (data.module === "FILE_MANAGERS") {
        if (data.type === "DONE") {
          /* dispatch(alertAction.successAlert(data.message, data.duration)) */
          dispatch(alertAction.successAlert(data.message, data.duration))
        } else {
          dispatch(alertAction.error(data.message, data.duration))
        }
        // update the notified status
        dispatch(fileManagerAction.upadateNotifiedtatus(data.data_id))
      } else if (data.module === "PROCESS_MANAGERS") {
        if (data.type === "DONE") {
          dispatch(alertAction.successAlert(data.message, data.duration))
        } else {
          dispatch(alertAction.error(data.message, data.duration))
        }
        // update the notified status
        dispatch(meSurveyAction.updateSeenStatus(data.survey_id || null, data.data_id, { notified: true }))
      }
    }
  }



  return (
    <>
      <Route {...rest} render={props => (
        (isLoggedIn)
          ? (
            <React.Fragment>
              {access === null
                ? <PageSetUpPreference />
                : <PrivateLayout {...props} user={user} access={access}> <Component {...props} user={user} /> </PrivateLayout>
              }
            </React.Fragment>
          )
          : <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )} />
    </>
  );
};

// Define Props Types
PrivateRoute.propTypes = {
  // component: PropTypes.elementType,
  location: PropTypes.object
};

export default PrivateRoute;
