import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import SurveyPreviewStyles from "../../SurveyPreviewStyles";

// Import Component
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../../../components/ButtonComponent/ButtonComponent";
import ErrMsgComponent from "./QuestionErrors/ErrMsgComponent";

//import Constants
import { surveyQuestionsConstants } from "../../../../../config/constants";

function YesorNo(props) {
  // Get Props Values
  const {
    classes,
    langCode,
    item,
    surveyQuesAnswer,
    errorStatus,
    questionIndex, index, continueBtn, changeValue
  } = props;
  const { ansSet, text, isRequired } = item;
  const ansYes = surveyQuesAnswer === "" ? null : surveyQuesAnswer;
  const [clickIndex, setClickIndex] = useState("");
  const handleContinueChange = () => {
    changeValue(false);
  };

  /* Todo: Have to check yes/no component  */
  const handleAnswerUpdate = (val, index) => {
    let value = val;
    value = ansYes != val ? val : null;
    handleContinueChange();
    setClickIndex(index)
    props.handleAnswerUpdate(value);
  };

  const questionText = text[langCode];
  const ansArr = ansSet[langCode];
  const answerSet = Object.values(ansArr);
  return (
    <React.Fragment>
      {/* YESNO */}
      <Grid
        container
        alignItems="center"
        className={classes.yesNoSingleQuestion}
      >
        <Grid item md={6} xs={12} className="pb5">
          <TypographyComponent variant="body1">
            {questionIndex}. {questionText} {isRequired ? "*" : ""}
          </TypographyComponent>
          {continueBtn ? errorStatus ?  <ErrMsgComponent /> : null : questionIndex === clickIndex && errorStatus ? <ErrMsgComponent />: null}
          </Grid>
        <Grid item md={6} xs={12}>
          <Grid container alignItems="center" justifyContent="flex-end">
            <ButtonComponent
              pageClassName={ansYes === 0 ? classes.yesNoActive : ""}
              title={answerSet[0]}
              handleClick={(e) => {
                handleAnswerUpdate(0, questionIndex);
              }}
            ></ButtonComponent>

            <ButtonComponent
              pageClassName={ansYes === 1 ? classes.yesNoActive : ""}
              title={answerSet[1]}
              handleClick={(e) => {
                handleAnswerUpdate(1, questionIndex);
              }}
            ></ButtonComponent>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
// default props
YesorNo.defaultProps = {
  classes: {},
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE,
  handleAnswerUpdate: () => {},
  errorStatus: false,
  continueBtn : false
};

// prop types
YesorNo.propTypes = {
  classes: PropTypes.object,
  langCode: PropTypes.string,
  handleAnswerUpdate: PropTypes.func,
  errorStatus: PropTypes.bool,
  continueBtn : PropTypes.bool
};
export default withStyles(SurveyPreviewStyles)(YesorNo);
