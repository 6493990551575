import { IsEmail } from "../../../../helpers";
import { surveyTypes, ReportsConstants } from "../../../../config/constants";

// Helper function to validate and format email addresses
const getEmailChips = (emailArr) => {
  return (emailArr || [])?.reduce((resultEmails, email) => {
    if (email?.trim()) {
      resultEmails.push({
        email: email.trim().toLowerCase(),
        isValid: IsEmail(email.trim())
      });
    }
    return resultEmails;
  }, []);
};

// Helper function to check for duplicate report names
const findReportNames = (isSaveReport, savedReports, name) => {
  let nameFound = false;
  (savedReports || [])?.map(item => {
    if (item && (item).toLowerCase() == (name).toLowerCase()) {
      nameFound = true;
    } else if (item && (item).toLowerCase() === (name).toLowerCase()) {
      nameFound = true;
    }
  })
  return nameFound;


};

// Validate survey selection and report name
const selectSurveyStep = (savedReports, reportData, isSaveReport) => {

  // if (!reportData?.name?.trim()) {
  //   return ReportsConstants.CUSTOM_VALID_REPORT_NAME;
  // }

  if (!reportData?.surveys?.length) {
    return ReportsConstants.CUSTOM_VALID_SURVEY;
  }

  // Validate survey type combinations
  const hasPulseSurvey = reportData?.surveys?.some(s => s?.frequency === "Pulse");
  const hasNonPulseSurvey = reportData?.surveys?.some(s => s?.frequency !== "Pulse");

  const hasMESurvey = reportData?.surveys?.some(s => s?.type === surveyTypes.MULTI_RATER_FEEDBACK);
  const hasNonMESurvey = reportData?.surveys?.some(s => s?.type !== surveyTypes.MULTI_RATER_FEEDBACK);

  if (hasPulseSurvey && hasNonPulseSurvey) {
    return ReportsConstants.CUSTOM_VALID_COMBINE_PULSE;
  }

  if (hasMESurvey && hasNonMESurvey) {
    return ReportsConstants.CUSTOM_VALID_COMBINE_MULTIRATER;
  }
  const nameFoundd = !isSaveReport ? findReportNames(isSaveReport, savedReports, reportData["name"]) : ""
  if (nameFoundd) {
    return ReportsConstants.CUSTOM_VALID_NAME_EXIST;
  }

  return true;
};

// Validate date selection
const chooseDatesStep = (reportData) => {
  const { date } = reportData;

  if (!date?.type || date?.type === " ") {
    return ReportsConstants.CUSTOM_VALID_SELECT_DATE;
  }

  if (date?.type === "Custom dates") {
    if (!date?.startDate) {
      return ReportsConstants.CUSTOM_VALID_START_DATE;
    }
    if (!date?.endDate) {
      return ReportsConstants.CUSTOM_VALID_END_DATE;
    }
    if (new Date(date?.startDate) > new Date(date?.endDate)) {
      return ReportsConstants.CUSTOM_VALID_DATE;
    }
  }

  return true;
};

// Validate demographic filters
const demographicStep = (reportData) => {
  // Demographics are optional in the initial filter step
  return true;
};

// Validate dashboard configuration
const dashboardStep = (reportData) => {
  // Dashboard configuration is optional
  return true;
};

// Validate charts configuration
const chartsStep = (reportData) => {
  // Charts configuration is optional
  return true;
};

// Validate comments configuration
const commentsStep = (reportData, commentReport, commentquestions) => {
  if (commentquestions?.length > 0 && (reportData?.is_sentiment || reportData?.is_verbatim) && (!reportData?.commentQuestions?.length)) {
    return ReportsConstants.CUSTOM_VALID_QUESTION;
  }
  return true;
};

// Validate organization settings
const organizeStep = (reportData) => {
  // Organization settings are optional
  return true;
};

// Validate schedule/save settings
const scheduleStep = (reportData) => {
  if (!reportData?.name?.trim()) {
    return ReportsConstants.CUSTOM_VALID_REPORT_NAME;
  }
  const emailIds = reportData?.emailIds || [];

  // Validate email schedule if provided
  if (emailIds && emailIds?.length === 0 && reportData?.frequency && reportData?.frequency != " ") {
    return "Please add an EmailID"
  }
  if (reportData.emailIds?.length > 0) {
    if (!reportData?.frequency || reportData?.frequency === " ") {
      return ReportsConstants.CUSTOM_VALID_FREQUENCY;
    }

    const invalidEmails = getEmailChips(reportData?.emailIds)?.filter(e => !e.isValid);
    if (invalidEmails?.length) {
      return ReportsConstants.CUSTOM_VALID_EMAIL;
    }
  }

  return true;
};

// Main stepper validation function
export const stepperValidation = (
  currentStep,
  reportData,
  commentReport,
  commentquestions,
  savedReports = [],
  buttonType,
  isSaveReport
) => {
  // If exporting, validate core requirements
  if (buttonType === "export") {
    const coreValidations = [
      selectSurveyStep(reportData),
      chooseDatesStep(reportData)
    ];

    const firstError = (coreValidations || [])?.find(result => result !== true);
    if (firstError) return firstError;
    return true;
  }

  // Step-specific validation
  switch (currentStep) {
    case 0: // Survey Selection
      return selectSurveyStep(savedReports, reportData, isSaveReport);
    case 1: // Dates
      return chooseDatesStep(reportData);
    case 2: // Filters/Demographics
      return demographicStep(reportData);
    case 3: // Dashboard
      return dashboardStep(reportData);
    case 4: // Charts
      return chartsStep(reportData);
    case 5: // Comments
      return commentsStep(reportData, commentReport, commentquestions);
    case 6: // Organize
      return organizeStep(reportData);
    case 7: // Schedule/Save
      return scheduleStep(reportData);
    default:
      return true;
  }
};