const DashboardGaugeComponentStyles = (theme) => ({
    gaugeContainer: {
        minWidth: "150px",
        // aspectRatio: 2 / 1,
        display: "flex",
        flexDirection : "column",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        // width:"50%",
        [theme.breakpoints.down('lg')]: {
            minWidth: "160px",
            maxWidth: "160px",
        }
    },
    gaugeInnerContainer: {
        position: 'relative',
        width:"150px",
        [theme.breakpoints.down('lg')]: {
            width: "160px",
        }
    },
    gaugeBlock: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        marginTop: '12px',
    },
    gaugeSubTitle: {
        color: '#B1B2B3',
        fontSize: "12px",
    },
    pointIndicator: {
        padding: '0px 6px',
        borderRadius: '25px',
        display: 'inline-block',
        position: 'relative',
        paddingLeft: '20px',
        margin: '-38px auto 0',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        fontSize: '11px',
        transform: 'translate(-50%, -12px)',
        width: 'max-content',
        display: 'block',
        '&.high': {
            color: '#42AA16',
            backgroundColor: '#b7eca34d',
        },
        '&.low': {
            backgroundColor: '#ffc9c9',
            color: '#e52323',
        },
        '&.neutral': {
            paddingLeft: '8px',
            backgroundColor: '#f3f4f5',
            color: '#b1b2b3',
        },
        '&::before': {
            content: '"\"',
            borderStyle: 'solid',
            position: 'absolute',
            top: '58%',
            marginTop: '-6px',
            left: '10px',
            borderWidth: '5px 7px 5px 0',
            borderColor: 'transparent currentColor transparent transparent',
        },
        '&.high::before': {
            transform: 'rotate(90deg)',
        },
        '&.low::before': {
            transform: 'rotate(270deg)',
        },
        '&.neutral::before': {
            border: 0,
        }
    },
    pointIndicatorText: {
        margin: '-4px 0 8px',
        // fontSize: '12px',
        // color: '#B1B2B3',
        // fontWeight: '400',
        display: 'block',
    },
    peopleCountBlock: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        // marginTop: '20px',
        color: '#B1B2B3',
    }
});

export default DashboardGaugeComponentStyles;