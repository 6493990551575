import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import TakeSurveyStyles from "../TakeSurveyStyles";

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import ErrMsgComponent from "./QuestionErrors/ErrMsgComponent";

//import Constants
import { surveyQuestionsConstants } from "../../../config/constants";

function SingleSelect(props) {
  // Get Props Values
  const {
    classes,
    langCode,
    item,
    singleSelectRepeat,
    surveyQuesAnswer,
    errorStatus,
    questionIndex,
    index,
    continueBtn,
    changeValue,
    continueBtn2,
    changeValue2
  } = props;
  const [clickIndex, setClickIndex] = useState("")
  const handleContinueChange = () => {
    changeValue(false);
  };
  const handleTabChange = () => {
    changeValue2(false);
  };
  function handleAnswerUpdate(val, index) {
    const value =
      surveyQuesAnswer || surveyQuesAnswer === 0
        ? surveyQuesAnswer !== val
          ? val
          : null
        : val;
    handleContinueChange();
    handleTabChange();
    setClickIndex(index)
    props.handleAnswerUpdate(value);
  }

  const { ansSet, text, isRequired } = item;
  const questionText = text[langCode];
  const ansArr = ansSet && ansSet.hasOwnProperty(langCode) ? ansSet[langCode] : null;
  const answerSet = ansArr ? Object.entries(ansArr) : [];
  const ansText = answerSet.filter((val) => val[0] === surveyQuesAnswer.toString())
  const zeroScaleSelect =
    answerSet.length > 0 && answerSet[0][0] == 0 ? true : false;
  const optionLength = answerSet.length - 1;
  const incRatio = optionLength > 5 ? 1 : 2;
  let colIncStart = optionLength > 5 ? 10 - optionLength : 5 - optionLength;
  const screenWidth = window.innerWidth
  return (
    <React.Fragment>
      {/* Each Multiple Question */}
      <Grid container className={classes.singleQuestion}>
        <Grid item md={6} xs={12} className={`${classes.singleSelectQuestion}`}>
          <Grid item className="pb5">
            <TypographyComponent variant="body1" extraProps={{ tabIndex: "0" }}>
              {questionIndex}. {questionText} {isRequired ? "*" : ""}
            </TypographyComponent>
          {continueBtn || continueBtn2 ? errorStatus ?  <ErrMsgComponent /> : null : questionIndex === clickIndex && errorStatus ? <ErrMsgComponent />: null}
            </Grid>
        </Grid>
        {answerSet.length > 0 ? (
          <Grid item md={6} xs={12}>
            <Grid container wrap="nowrap">
              {answerSet.map((ans, key) => {
                colIncStart++;
                return (
                  <Grid
                    item
                    xs={3}
                    className={`${"answerSet"} ${classes.answerSet}`}
                    key={key}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      className="height100"
                    >
                      {!singleSelectRepeat ? (
                        <Grid item className={answerSet.length > 6 ? classes.showDiv : ""}>
                          <TypographyComponent
                            variant="body1"
                            className="txtcenter answerSettxt pb5"
                          >
                            {ans[1]}
                          </TypographyComponent>
                        </Grid>
                      ) : null}
                      {screenWidth > 768 ?
                        <Grid item>
                          <ButtonComponent
                            pageClassName={`${classes.singleSelectTile} ${surveyQuesAnswer &&
                              ((zeroScaleSelect && surveyQuesAnswer == key) ||
                              !zeroScaleSelect && surveyQuesAnswer === key + 1) &&
                              ans[0] !== "0"
                              ? `activeSelectBlue`
                              : surveyQuesAnswer === 0 && ans[0] === "0"
                                ? `activeSelect op0`
                                : ""
                              } `}
                            title={ans[0] === "0" ? "NA" : ans[0]}
                            handleClick={(e) => {
                              handleAnswerUpdate(Number(ans[0]), questionIndex);
                            }}
                          ></ButtonComponent>
                        </Grid> :
                        <Grid item>
                          <ButtonComponent
                            pageClassName={`${classes.singleSelectTile} ${surveyQuesAnswer &&
                              ((zeroScaleSelect && surveyQuesAnswer == key) ||
                                !zeroScaleSelect && surveyQuesAnswer === key + 1) &&
                              ans[0] !== "0"
                              ? `activeSelectBlue`
                              : surveyQuesAnswer === 0 && ans[0] === "0"
                                ? `activeSelect op0`
                                : ""
                              } `}
                            title={ans[0] === "0" ? "NA" : ans[0]}
                            handleClick={(e) => {
                              handleAnswerUpdate(Number(ans[0]), questionIndex);
                            }}
                          ></ButtonComponent>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            {(surveyQuesAnswer || surveyQuesAnswer === 0) && screenWidth < 768 &&
              <TypographyComponent variant="body1" className="txtcenter colorTheme">
                {ansText[0][1]}
              </TypographyComponent>
            }
          </Grid>
        ) : null}
      </Grid>
    </React.Fragment>
  );
}
// default props
SingleSelect.defaultProps = {
  classes: {},
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE,
  handleAnswerUpdate: () => { },
  singleSelectRepeat: false,
  errorStatus: false,
  continueBtn : false
};

// prop types
SingleSelect.propTypes = {
  classes: PropTypes.object,
  langCode: PropTypes.string,
  handleAnswerUpdate: PropTypes.func,
  singleSelectRepeat: PropTypes.bool,
  errorStatus: PropTypes.bool,
  continueBtn: PropTypes.bool,
  
};
export default withStyles(TakeSurveyStyles)(SingleSelect);
