import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Link } from "react-router-dom";

// Import Styles and Image
import ReportsStyles from "../../../ReportsStyles";

// Import Helpers
import { addEllipsis } from "../../../../../helpers";

// Import Component
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";

// import constants
import { ReportsConstants } from "../../../../../config/constants";

//Import Actions
import { reportAction } from "../../../../../redux/actions";

function ComentQuestions(props) {
  const dispatch = useDispatch();

  // get props
  const { classes, commentQuestions, commentReport, isDrawer, handleClear } = props;
  
  useEffect(() => {
    commentReport.commentQuestions = [];
    dispatch(reportAction.updateCommentReport(commentReport));
  }, [])
  
  //update comment questions
  const updateQuestions = (id) => {
    if (commentReport.commentQuestions.indexOf(id) > -1) {
      commentReport.commentQuestions = commentReport.commentQuestions.filter((d) => d !== id);
    } else {
      commentReport.commentQuestions.push(id);
    }
    dispatch(reportAction.updateCommentReport(commentReport));
  };

  //check box true/false
  const getCheckedData = (id) => {
    return commentReport.commentQuestions.filter((d) => d === id).length > 0 ? true : false;
  };

  //select or un select all questions
  const selectAllQuestions = () => {
    if (commentReport.commentQuestions.length === commentQuestions.length) {
      commentReport.commentQuestions = [];
    } else {
      commentReport.commentQuestions = commentQuestions.map((item) => { return item.baseQusId })
    }
    dispatch(reportAction.updateCommentReport(commentReport));
  };

  //select or un select all questions
  const clearAllQuestions = () => {
    commentReport.commentQuestions = [];
    dispatch(reportAction.updateCommentReport(commentReport));
    handleClear()
  };

  return (
    <React.Fragment>
      <Grid item xs={isDrawer ? 12 : 6}>
        <Grid container>
          <Grid item xs={6}>
            <TypographyComponent variant="h6">
              {ReportsConstants.SELECT_QUESTIONS}
            </TypographyComponent>
          </Grid>
          {
            isDrawer && (
              <Grid item sm={6} className={`${classes.clearAllPosition} ${isDrawer ? 'drawer txt-right' : ''}`}>
                <Link
                  className={`cursor-pointer colorTheme right fontSize14`}
                  onClick={(e) => {
                    e.preventDefault()
                    clearAllQuestions()
                  }}
                >
                  {"Clear all"}
                </Link>
              </Grid>
            )
          }
        </Grid>
        <Grid className={`pl5 ${classes.commentQuesHeight}`}>
          <FormControlLabel
            control={
              <Checkbox
                key={`allSelection`}
                id={`allSelect`}
                name={`allQuesSelection`}
                color="primary"
                checked={commentReport.commentQuestions.length === commentQuestions.length}
                onChange={(e) => selectAllQuestions()}
              />
            }
            label={"Select all"}
          />
          {commentQuestions.map((data) => (
            <Grid item xs={12} key={"div_" + data.baseQusId}>
              <Tooltip title={data.question} arrow>
                <FormControlLabel
                  control={
                    <Checkbox
                      key={data.baseQusId}
                      id={data.baseQusId}
                      name={data.question}
                      color="primary"
                      checked={getCheckedData(data.baseQusId)}
                      onChange={(e) => updateQuestions(data.baseQusId)}
                    />
                  }
                  label={addEllipsis(data.question, 70)}
                />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
// default props
ComentQuestions.defaultProps = {
  classes: {},
  commentReport: {},
  commentQuestions: [],
  isDrawer: false
};

// prop types
ComentQuestions.propTypes = {
  classes: PropTypes.object,
  commentReport: PropTypes.object,
  commentQuestions: PropTypes.array,
};
export default withStyles(ReportsStyles)(ComentQuestions);
