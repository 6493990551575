import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";

// Import Styles and Image
import TakeSurveyStyles from "../TakeSurveyStyles";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import AutoCompleteComponent from "../../../../components/AutoCompleteComponent/AutoCompleteComponent";
import ErrMsgComponent from "./QuestionErrors/ErrMsgComponent";

//import Constants
import { surveyQuestionsConstants } from "../../../../config/constants";

function Dropdown(props) {
  // Get Props Values
  const { classes, langCode, item, surveyQuesAnswer, errorStatus, questionIndex, continueBtn, changeValue, continueBtn2, changeValue2} = props;
  const { baseQusId, ansSet, text, isRequired } = item;
  const questionText = text[langCode];
  const ansOptions = ansSet && ansSet.hasOwnProperty(langCode) ? ansSet[langCode] : null;
  const answerSet = ansOptions ? Object.values(ansOptions) : [];
  const answerSetEN = ansSet && ansSet["en"] ? (ansSet["en"] || {}) : [];
  const [width, setWidth] = useState(window.innerWidth);
  const [clickIndex, setClickIndex] = useState("")
  const selectLang = useSelector((state) => state.meTakeSurvey.surveyLang);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const newAnsSet = ansSet[selectLang];
    if (answerKey) {
      const newAnswer = newAnsSet[answerKey];
      setAnswer(newAnswer);
    }
  }, [selectLang]);

  let selectedKey = null;
  if (surveyQuesAnswer) {
    selectedKey = Object.keys(ansOptions || {})?.find((key) => ansOptions[key] === surveyQuesAnswer);
  }

  const [answer, setAnswer] = useState(surveyQuesAnswer);
  const [answerKey, setAnswerKey] = useState(selectedKey);
  const handleContinueChange = () => {
    changeValue(false);
  };
  const handleTabChange = () => {
    changeValue2(false);
  };
  
  const handleChange = (e, index) => {
    let ans = "";
    if (width <= 768) {
      ans = e?.target?.value;
    } else {
      ans = e;
    }
    const selectedVal = ans ? ans : null;
    const selectedKey = Object.keys(ansOptions || {})?.find((key) => ansOptions[key] === ans);
    setAnswer(selectedVal);
    setAnswerKey(selectedKey);
    handleContinueChange();
    handleTabChange();
    setClickIndex(index)
    props.handleAnswerUpdate(selectedVal, answerSetEN[`${selectedKey}`] || null);
  };

  return (
    <React.Fragment>
      <Grid container alignItems="center" className={classes.dropdownSingleQuestion}>
        <Grid item md={6} xs={12} className="pb5">
          <TypographyComponent variant="body1" extraProps={{ tabIndex: "0" }}>
            {questionIndex}. {questionText} {isRequired ? "*" : ""}
          </TypographyComponent>
          {continueBtn || continueBtn2 ? errorStatus ?  <ErrMsgComponent /> : null : questionIndex === clickIndex && errorStatus ? <ErrMsgComponent />: null}
        </Grid>

        {answerSet?.length > 0 ? (
          <Grid item md={6} xs={12} className="takesurvey">
            <Grid xs={8} item className={classes.answerSet}>
              <ValidatorForm name="ForgotPasswordForm" useref="form" autoComplete="off" onError={(errors) => console.log(errors)}>
              {width <= 768 ? <Box sx={{ minWidth: 180 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={answer ? answer : ""}
                      displayEmpty
                      renderValue={(value) => {
                        if (value === "") {
                          return <span style={{color: "grey"}}>{ReportsConstants.DROPDOWN_SELECT_ANSWER}</span>;
                        }
                        return value;
                      }}
                      onChange={(e)=>handleChange(e, questionIndex)}

                      MenuProps={{
                        PaperProps: {
                          sx: {
                            // bgcolor: 'pink',
                            '& .MuiMenuItem-root:first-child': {
                              paddingTop: '10px',
                              paddingBottom: '10px'
                            },
                            '& .MuiMenuItem-root': {
                              minHeight: '10px',
                              paddingTop: '1px',
                              paddingBottom: '1px',
                            },
                          },
                        },
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center'
                        },
                      }}
                    >
                      {["", ...answerSet]?.map((item) => { return <MenuItem value={item}>{item}</MenuItem> })}

                    </Select>
                  </FormControl>
                </Box> : <AutoCompleteComponent
                  name={baseQusId}
                  id={`qus_${baseQusId}`}
                  variant="outlined"
                  value={answer ? answer : "" }
                  suggestions={answerSet}
                  fullWidth={true}
                  handleAutoCompolete={(e)=>handleChange(e, questionIndex)}
                  placeholder="Select Answer"
                  className={classes.dropdownTextBox}
                  suggestionType="array"
                  disableClearable={answer ? false : true}
                  onKeyDown={props.onKeyDown}
                  disablePortal={false}
                  blurOnSelect={true}
                  selectOnFocus={true}
                  readOnly={false}

                />}
              </ValidatorForm>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </React.Fragment>
  );
}
// default props
Dropdown.defaultProps = {
  classes: {},
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE,
  handleAnswerUpdate: () => {},
  errorStatus: false,
  continueBtn : false
};

// prop types
Dropdown.propTypes = {
  classes: PropTypes.object,
  langCode: PropTypes.string,
  handleAnswerUpdate: PropTypes.func,
  errorStatus: PropTypes.bool,
  continueBtn: PropTypes.bool
};
export default withStyles(TakeSurveyStyles)(Dropdown);
