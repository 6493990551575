import React from "react";
import { useSelector,useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";
import _ from "lodash";

// Import Styles and Image
import { DashboardClose } from "../../../../assets/images/svgComponents/ImageComponent";
import ReportsStyles from "../../ReportsStyles";

//import components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import StackedBar from "../../../../components/Charts/StackedBarChart/StackedBar";

// Import Constants
import { ReportsConstants } from "../../../../config/constants";

//import action
import { dashboardAction } from "../../../../redux/actions";

function EiRgiPopup(props) {
  const dispatch = useDispatch();

  //get props
  const { classes, onClose, saveReportPreferences, preference, handleSorting } = props;

  //get reducer data
  const {EiRgi:chartData, keyArr} = useSelector((state) => state.dashboard.engagementIndex);
  const metadataWithRGIAll = useSelector((state) => state.metadata.metadataWithRGI);
  const { demographicsDropdown:demographics } = useSelector((state) => state.filter);

  const demographicsData = Object.keys(demographics)
  let metadataWithRGI = metadataWithRGIAll.filter(d => (d.visible === true && d.visibleInFilter === true && demographicsData.indexOf(d.key) > -1))
  metadataWithRGI = _.orderBy(metadataWithRGI, ["value"], ["asc"]);

  //set the selected report group value from the poreference
  let selectedRg = preference &&  preference["EI"] && Object.keys(demographics).includes(preference["EI"]["rg"])? preference["EI"]["rg"] : Object.keys(demographics)[0];

  let selectedMetadataWithRGI = {};
  if (selectedRg) {
    selectedMetadataWithRGI = Array.isArray(metadataWithRGI) && metadataWithRGI.length > 0 ? metadataWithRGI.filter(d => d.key === selectedRg && d.visible && d.visibleInFilter)?.[0] : {}
  }
  let { dataType } = selectedMetadataWithRGI;
  const selectedMetadaDataType = dataType ? dataType : 'string';

  // sort
  let yAxisSortField = preference?.["EI"]?.["yAxisSort"]?.sortField ? preference["EI"]["yAxisSort"].sortField : 1
  let yAxisSortBy = preference?.["EI"]?.["yAxisSort"]?.sortBy ? preference["EI"]["yAxisSort"].sortBy : "desc"

  //default chart data and color
  const groupArr = [
    "Actively Disengaged",
    "Opportunity Group",
    "Engaged/Contributor",
    "Actively Engaged",
  ];
  const rangeArr = {
    "Actively Disengaged": "#f8ad4a",
    "Opportunity Group": "#fbcb90",
    "Engaged/Contributor": "#94d6fb",
    "Actively Engaged": "#54bffb",
  };

  const getSortOptions = (options) => {
    if (selectedMetadaDataType === 'integer' || selectedMetadaDataType === 'date') {
      const updateAscValue = selectedMetadaDataType === 'integer' ? '0 - 9' : 'Oldest - Newest';
      const updateDescValue = selectedMetadaDataType === 'integer' ? '9 - 0' : 'Newest - Oldest';

      if (options?.[0]?.['name'] && options?.[0]?.['name'] === 'A - Z') {
        options[0]['name'] = updateAscValue;
      }
      if (options?.[1]?.['name'] && options?.[1]?.['name'] === 'Z - A') {
        options[1]['name'] = updateDescValue;
      }
    }

    return options;
  }

  // custom demographics sort function
  const sortProperties = yAxisSortField === 3 ? groupArr.reverse() : groupArr;
  const demographicsSortFunction = (item) => {
    if ([1,2].includes(yAxisSortField)) {
      const meanRemovedDemographic = item.yAxis.replace(/\s\s\(N=\d+\)$/g, "")

      if (ReportsConstants?.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY?.[selectedRg]) {
        return ReportsConstants?.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY?.[selectedRg]?.[meanRemovedDemographic]
      } else if (selectedMetadaDataType === 'integer' || selectedMetadaDataType === 'date') {
        const parsedValue = selectedMetadaDataType === 'integer' ? parseInt(meanRemovedDemographic) : new Date(meanRemovedDemographic);
        return !isNaN(parsedValue) ? parsedValue : meanRemovedDemographic;
      } else {
        return meanRemovedDemographic.toLowerCase()
      }
    } else if ([3,4].includes(yAxisSortField)) {
      const presentProperty = sortProperties.reduce((acc, curr) => {
        if (acc === undefined && chartData.some(data => data?.[curr] !== undefined) && keyArr.indexOf(curr) !== -1) {
          acc = curr
        }

        return acc;
      }, undefined);
      const val = item?.[presentProperty]
  
      return -val;
    } else if ([5,6].includes(yAxisSortField)) {
      if(keyArr.length===groupArrCopiedForSorting.length){
        const presentProperty = keyArr.reduce((acc, curr) => {
            if (acc === undefined && chartData.some(data => data?.[curr] !== undefined) && keyArr.indexOf(curr) !== -1) {
                acc = curr
            }
    
            return acc;
        }, undefined);
        const val = item?.[presentProperty]
        return -val;
    }else{
      const presentProperty = keyArr.reduce((acc, curr) => {
          if (chartData.some(data => data?.[curr] !== undefined) && keyArr.indexOf(curr) !== -1) {
              acc.push(curr);
          }
  
          return acc; 
      }, []);
      const val = presentProperty.map(prop => item[prop]).reduce((acc, value) => acc + (value || 0), 0)
      return -val;
    }
    }
  }

  let chartDataCopiedForSorting = [...chartData]
  let groupArrCopiedForSorting = [...groupArr]
  let keyArrCopiedForSorting = [...keyArr]

  chartDataCopiedForSorting = _.orderBy(chartDataCopiedForSorting, [demographicsSortFunction], yAxisSortBy)
  if (yAxisSortBy === "desc" && yAxisSortField === 3) {
    keyArrCopiedForSorting.reverse();
  } else if (yAxisSortBy === "asc" && yAxisSortField === 4) {
    chartDataCopiedForSorting = chartDataCopiedForSorting.reverse();
  }


  // get display name for auto complete dropdown
  const getOptionSelected = (options, value) => {
    let optionSelected = options.filter((p) => p.key === value)[0] || "";
    return optionSelected;
  };

  const keyarrChange = (value, quesId) => {
    const updatedValue = yAxisSortBy === 'desc' && yAxisSortField === 3 ? value.reverse() : value;
    dispatch(dashboardAction.eiKeysArrUpdate(updatedValue))
  }

  // get display name for sortby options
  const getSeletedSortOption = (options, value) => {
    let sortOptionSelected = options.filter((item) => item.value === value)[0] || ""
    return sortOptionSelected
  }

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        className={`${classes.EIheight100}`}
      >
        <Grid item xs={6} className="height-90">
          <TypographyComponent variant="h4" className="txtBold fontSize16  ml-1 mt-2">
            {"Engagement"}
          </TypographyComponent>
        </Grid>
        <Grid item xs={6} className={`${classes.dialog_close} ${classes.EIheight10}`}>
          <Button variant="text" size={"small"} onClick={onClose} className="mt-2">
            {DashboardClose()}
            <TypographyComponent variant="h5" className="pl5">
              {"Close"}
            </TypographyComponent>
          </Button>
        </Grid>
        {/* <Grid container className={"justifycenter mb-6"}>
          <OverallEIChart
            classes={classes}
            chartId={"EichartsPop"}
          />
        </Grid> */}

        <Grid item xs={12} className={`${classes.EIheight90}`}>
          <Grid className={classes.EIWidth70}>
            <Grid container>
              <Grid item md={5}>
                <Grid container>
                  <Grid md={5} className={"pt-1 paddingLefts"}>
                    <TypographyComponent
                      className={"txtBold txtcenter"}
                      title={ReportsConstants.VIEW_BY}
                      variant={"h6"}
                    ></TypographyComponent>
                  </Grid>
                  <Grid md={6} className={"pr-1"}>
                    <Autocomplete
                      id={"EngagnementIndexRGI"}
                      disableClearable
                      autoHighlight
                      blurOnSelect
                      options={metadataWithRGI}
                      value={getOptionSelected(metadataWithRGI, selectedRg)}
                      className={`input-value-editor autocomplete  ${classes.boxMaxWidth}`}
                      getOptionLabel={(option) => option.value || ""}
                      onChange={(e, val) => {
                        selectedRg = val.key;
                        saveReportPreferences(selectedRg)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant={"outlined"}
                          className={`${"height28 conversionAutoComplete boderRadius4"}`}
                          placeholder="Select Field"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={5}>
                <Grid container>
                  <Grid md={5} className={"pt-1 paddingLefts"}>
                    <TypographyComponent
                      className={"txtBold txtcenter"}
                      title={ReportsConstants.SORT_BY}
                      variant={"h6"}
                    ></TypographyComponent>
                  </Grid>
                  <Grid md={6} className={"pr-1"}>
                    <Autocomplete
                      id={"EngagnementIndexRGI"}
                      disableClearable
                      autoHighlight
                      blurOnSelect
                      sx={{ width: 170 }}
                      options={getSortOptions(ReportsConstants.EICHART_SORT_OPTIONS)}
                      value={getSeletedSortOption(ReportsConstants.EICHART_SORT_OPTIONS, yAxisSortField)}
                      className={`input-value-editor autocomplete`}
                      getOptionLabel={(option) => option.name || ""}
                      onChange={(e, val)=>{handleSorting({sortField: val.value, sortBy: val.sortBy})}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant={"outlined"}
                          className={`${"height28 conversionAutoComplete boderRadius4"}`}
                          placeholder="Select Field"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid> 

            <Grid className={`ml-auto pr-4 pt-2 heightAuto ${classes.paddingLeft120}`} md={11}>
              {chartData && chartData.length > 0 && (
                <StackedBar
                  chartId={"engagementIndexRgiZoom"}
                  chartData={chartDataCopiedForSorting}
                  groupArr={groupArrCopiedForSorting}
                  zoomMode={true}
                  rangeArr={rangeArr}
                  keyarrChange={keyarrChange}
                  keyArr={keyArrCopiedForSorting}
                  chartWidth={1000}
                  chartHeight={320}
                />
              )}
              {(!chartData || chartData.length === 0) && (
                <Grid container>
                  <TypographyComponent variant="h3">{ReportsConstants.NO_DATA_CHART}</TypographyComponent>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
// default props
EiRgiPopup.defaultProps = {
  classes: {},
  onClose: () => { },
};

// prop types
EiRgiPopup.propTypes = {
  classes: PropTypes.object,
  onClose: PropTypes.func,
};
export default withStyles(ReportsStyles)(EiRgiPopup);
