import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

//Import Component
import CategoryTabs from "./CategoryTabs";
import QuestionsPanel from "./QuestionsPanel";

// Import Config Layout
import { getQuestionPanelHeight } from "../../../../config/layout";
import { isMobile } from "react-device-detect";

//Import Constants
import {
  surveyQuestionsConstants,
  TakeSurveyConstants,
} from "../../../../config/constants";

// Import Actions
import { surveyPreviewAction, alertAction } from "../../../../redux/actions";

function TakeSurveyForm(props) {
  // Define Dispatch
  const dispatch = useDispatch();
  const surveyLang = useSelector((state) => state.surveyPreview.surveyLang);
  const surveyResponse = useSelector((state) => state.surveyPreview.response);
  const surveyRequiredQuestions = useSelector(
    (state) => state.surveyPreview.surveyRequiredQuestions
  );
  const activeCategoryTab = useSelector(
    (state) => state.surveyPreview.activeCategoryTab
  );

  //get props
  const { classes, questionData } = props;
  const { questions, catOrder } = questionData;
  const { languages } = questionData;

  const questionPanelHeight =
    isMobile && languages.length > 1
      ? getQuestionPanelHeight() - 20
      : isMobile
      ? getQuestionPanelHeight() + 5
      : getQuestionPanelHeight() - 10;
  const [onLoadHeight, setOnLoadHeight] = useState(questionPanelHeight);


  /* //Tabs
  const [activeCategoryTab, setActiveCategoryTab] = useState(activeCategoryTab);
  console.log("TakeSurveyForm -> activeCategoryTab", activeCategoryTab);

  
  //Get Survey
  useEffect(() => {
    dispatch(surveyPreviewAction.setActiveCategoryTab(activeCategoryTab));
  }, [dispatch, activeCategoryTab]); */

  //Active Categories when apply filters
  let activeCategories = [];
  questions.forEach((ques) => {
    if (!activeCategories.includes(ques.category)) {
      activeCategories.push(ques.category);
    }
  });
  const newCatOrder = catOrder.filter((cat) => activeCategories.includes(cat));
  const categoryCount = newCatOrder.length - 1;

  // const questionPanelHeight = getQuestionPanelHeight() + 10;

  const validatedQues = useSelector(
    (state) => state.surveyPreview.validatedQues
  );

  function handleTabChange(newValue) {
    const activeCategory = newCatOrder[activeCategoryTab];

    const activeCatRequiredQues = questions.filter(
      (ques) => ques.category === activeCategory && ques.isRequired
    );

    let errorQuestions = [];
    if (activeCatRequiredQues.length > 0) {
      activeCatRequiredQues.forEach((ques) => {
        if (!surveyRequiredQuestions[ques.baseQusId]) {
          errorQuestions = [...errorQuestions, ques.baseQusId];
        }
      });
    }

    if (errorQuestions.length > 0) {
      let requiredQuesArr = [...validatedQues];
      errorQuestions.forEach((ques) => {
        if (!validatedQues.includes(ques)) {
          requiredQuesArr.push(ques);
        }
      });
      dispatch(surveyPreviewAction.updateRequiredQuestions(requiredQuesArr));
    } else {
      dispatch(surveyPreviewAction.setActiveCategoryTab(newValue));
    }
  }
  
  function handleSurveyContinue() {
    if (activeCategoryTab < categoryCount) {
      const newValue = activeCategoryTab + 1;
      dispatch(surveyPreviewAction.setActiveCategoryTab(newValue));
    } else {
      if (inputValidationCheck()) {
        // "Survey Submit"
        handleSubmit();
      }
    }
  }

  function inputValidationCheck() {
    let errorQuestions = [];
    if (questions.length > 0) {
      questions.forEach((ques) => {
        if (
          surveyRequiredQuestions.hasOwnProperty(ques.baseQusId) &&
          !surveyRequiredQuestions[ques.baseQusId]
        ) {
          errorQuestions = [...errorQuestions, ques.baseQusId];
        }
      });
    }
    
    /* if (surveyResponse.length == 0) {
      //No zero answer message validation for preview survey
      dispatch(
        alertAction.error(TakeSurveyConstants.ANSWER_ANY_ONE_QUES_ERROR_MESSAGE)
      );
      return false; 
     
    } else  */
    
    if (errorQuestions.length > 0) {
      dispatch(surveyPreviewAction.updateRequiredQuestions(errorQuestions));
      dispatch(
        alertAction.error(TakeSurveyConstants.ANSWER_ALL_REQUIRED_ERROR_MESSAGE)
      );
      return false;
    } else {
      return true;
    }
  }

  function handleSubmit() {
    dispatch(surveyPreviewAction.previewSubmit());
  }

  return (
    <>
      <Grid container alignItems="center">
        <CategoryTabs
          classes={classes}
          questionData={questionData}
          changeTabValue={handleTabChange}
          activeCategoryTab={activeCategoryTab}
          langCode={
            surveyLang ? surveyLang : surveyQuestionsConstants.DEFAULT_LANG_CODE
          }
        />
        <Grid
          item
          sm={12}
          style={{ height: onLoadHeight }}
          className={"scrolly bgwhite pr-1"}
        >
          <QuestionsPanel
            activeCategoryTab={activeCategoryTab}
            questionData={questionData}
            classes={classes}
            langCode={
              surveyLang
                ? surveyLang
                : surveyQuestionsConstants.DEFAULT_LANG_CODE
            }
            handleSurveyContinue={handleSurveyContinue}
            questionPanelHeight={onLoadHeight}
          ></QuestionsPanel>
        </Grid>
      </Grid>
    </>
  );
}
// default props
TakeSurveyForm.defaultProps = {
  classes: {},
  questionData: { questions: [], catOrder: [], catDetails: {} },
};

// prop types
TakeSurveyForm.propTypes = {
  classes: PropTypes.object,
  questionData: PropTypes.object,
};
// export default TakeSurveyForm;
export default TakeSurveyForm;
